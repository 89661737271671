<template>
	<div>
		<h1 class="text-h5 q-pb-md q-my-md text-left fields-tab-header">Colours</h1>
		<div class="settings-wrapper row">
			<colour-picker
				v-for="colour in colours"
				:key="colour.label"
				:colour="colour"
			/>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import ColourPicker from '@/components/custom_branding/ColourPicker.vue'
export default {
	name: 'CustomBrandingColours',
	components: {
		ColourPicker,
	},
	data() {
		return {
			colours: [],
		}
	},
	computed: {
		...mapGetters(['custom_branding', 'current_CG', 'module_name_plural']),
	},
	created() {
		this.colours = [
			{
				label: 'Header colour',
				name: 'header-colour',
				value: 'header_color',
				default_value: '#118f99',
			},
			{
				label: 'Header icons colour',
				name: 'header-icons-colour',
				value: 'header_icons_color',
				default_value: '#ffffff',
			},
			{
				label: 'Highlight colour',
				name: 'highligh-colour',
				value: 'highlight_color',
				default_value: '#118f99',
			},
		]
		let has_incident_permission =
			this.current_CG.root_customer_group_permissions.includes('Tasks')
		if (has_incident_permission) {
			this.colours.push({
				label: `Highlight ${this.module_name_plural} colour`,
				name: 'highlight-incident-color',
				value: 'highlight_incident_color',
				default_value: '#118f99',
			})
		}
	},
}
</script>
