<template>
	<div class="col-sm-6 col-12 q-pa-sm">
		<q-input
			v-model="value"
			filled
			hide-bottom-space
			:style="{ borderBottom: `10px solid ${value}` }"
			:label="`${colour.label}`"
			:error="hasError(colour.value)"
			:error-message="branding_errors[colour.value]"
		>
			<template v-slot:append>
				<q-btn flat icon="colorize" class="cursor-pointer colour-btn">
					<q-popup-proxy transition-show="scale" transition-hide="scale">
						<q-color v-model="value" />
					</q-popup-proxy>
				</q-btn>
			</template>
		</q-input>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
	name: 'ColourPicker',
	props: {
		colour: Object,
	},
	computed: {
		...mapGetters(['branding_errors']),
		value: {
			get() {
				return this.$store.getters.custom_branding[this.colour.value]
					? this.$store.getters.custom_branding[this.colour.value]
					: this.colour.default_value
			},
			set(val) {
				let custom_branding = JSON.parse(
					JSON.stringify(this.$store.getters.custom_branding)
				)
				custom_branding[this.colour.value] = val
				this.updateCustomBranding(custom_branding)
			},
		},
	},
	methods: {
		...mapActions(['updateCustomBranding']),
		hasError(value) {
			return this.branding_errors[value] ? true : false
		},
	},
}
</script>
<style lang="scss">
	.colour-btn .q-btn__wrapper {
		padding: 0.5rem;
	}
</style>
